import { Link, useLocation, useNavigate } from "react-router-dom";
import { setModal } from "../../appState/componentSlices/userSlice/userLoginSlice";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { CartIconContainer } from "../cartIconContainer/CartIconContainer";
import { NavBarOptions } from "../navbarOptions/NavbarOptions";
const homeIcon = "/Images/home-com.svg";
const categoriesIcon = "/Images/categories.svg";
const ordersIcon = "/Images/shop-solid.svg";
const profileIcon = "/Images/user-solid.svg";
const Footer = () => {
  const { pathname } = useLocation();
  if(pathname === "/product-description"){
    return <></>
  }
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    userOtp: { isLoggedIn },
    util: { disableFooter },
  } = useAppSelector((state) => state);

  const handleClick = (url: string) => {
    if (!isLoggedIn) {
      return dispatch(setModal(true));
    }
    navigate(url);
  };
  return (
    <>
      {!disableFooter && (
        <div className="footer-container">
          <NavBarOptions
            icon={homeIcon}
            heading="Home"
            onClick={() => navigate("/")}
          />
          <NavBarOptions
            icon={ordersIcon}
            heading="Orders"
            onClick={() => handleClick("/allOrders")}
          />
          <div
            className="cart-icon-container"
            onClick={() => handleClick("/cart")}
          >
            <CartIconContainer />
          </div>
          <NavBarOptions
            icon={profileIcon}
            heading="Profile"
            onClick={() => handleClick("/profile")}
          />

          <NavBarOptions
            icon={categoriesIcon}
            heading="Categories"
            onClick={() => navigate("/categories")}
          />
        </div>
      )}
    </>
  );
};

export { Footer };
