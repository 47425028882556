export const getPriceBySlab = (slabPricing: string[][], quantity: number) => {
  const slabMap: Record<string, number> = {};
  slabPricing?.forEach(([_, quan, price]: string[]) => {
    slabMap[quan] = Number(price);
  });
  let price = quantity;
  Object.keys(slabMap)?.forEach((quan) => {
    if (quantity >= Number(quan)) {
      price = slabMap[quan] * quantity;
    }
  });
  return price.toFixed(2);
};
