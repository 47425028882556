import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../appState/store';
import { Card } from '../card/Card';
import { ProductCard } from '../productCard/ProductCard';
import { Product } from '../../appState/componentSlices/allProductsFeed';
import { ErrorMessage } from '../errorMessage/errorMessage';
import { errorMessages } from '../../constant/errorMessages';
import LoadingSpinner from '../loader/LoadingSpinner';
import ProductDropdownCard from '../productDropdownCard/ProductDropdownCard';

interface SearchProductListProps {
  products: any;
  loader:boolean
}

export const SearchProductList = (props:SearchProductListProps) => {
  const {products,loader} = props
  const icon = "/Images/emptyCart.svg";
  return (
    <div className='search-Products-component-main'>
      <div className='search-Products-component'>
        {!loader ? (
          products?.data?.length > 0 ? (
            products?.data?.map(
              (product: Product, index: number) => {
                return (
                  <Card key={index}>
                    <ProductDropdownCard product={product}/>
                  </Card>
                );
              }
            )
          ) : (
            <div className="no-products-container">
              <p><b>{products?.message}</b></p>
            </div>
          )
        ) : (
          <div className="spinner-container-main">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  )
}
