import { useState } from "react";
import { CartQuantityButton } from "../cartQuantityButton/CartQuantityButton";
function ProductTile(item: any) {
  const [productQuantity, setProductQuantity] = useState(item.productQuantity);
  const { images = [] } = item?.productDetails;
  return (
    <div className="product-tile-container">
      <div className="image-container">
        <img className="cart-item-image" src={images?.[0]?.secureUrl} alt="" />
      </div>
      <CartQuantityButton
        quantity={productQuantity}
        showAddButton={false}
        product={item}
        setProductQuantity={setProductQuantity}
      />
    </div>
  );
}

export { ProductTile };
