import { useNavigate } from "react-router";
import "./AccountDeletion.scss";

export const AccountDeletion = () => {
  const navigate = useNavigate();
  return (
    <div className="account-deletion-container">
      <h2>Request Account Deletion</h2>
      <p>
        If you would like to delete your account and associated data, please
        follow the steps outlined below. This process applies to users of{" "}
        <strong>Your App Name</strong>, as listed on the Google Play Store.
      </p>
      <ol className="deletion-steps">
        <li>
          Click the <strong>"Request Account Deletion"</strong> button below.
        </li>
        <li>
          Complete the form by providing your registered email and confirming
          your request.
        </li>
        <li>
          Allow up to <strong>30 days</strong> for your request to be processed.
        </li>
      </ol>
      <button
        className="deletion-link"
        onClick={()=>navigate("/request-deletion")}
      >
        Request Account Deletion
      </button>
      <div className="data-retention-info">
        <h3>Data Deletion and Retention Policy</h3>
        <p>
          The following data will be permanently deleted upon account deletion:
        </p>
        <ul>
          <li>Personal information (e.g., name, email address)</li>
          <li>Usage data and activity history</li>
          <li>Payment and subscription details</li>
        </ul>
        <p>The following data may be retained for legal or security reasons:</p>
        <ul>
          <li>Transaction records (retained for up to 1 year)</li>
          <li>Logs for security and fraud prevention (retained for 90 days)</li>
        </ul>
        <p>
          For more details, visit our{" "}
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </div>
  );
};
