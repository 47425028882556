// create async thunk for getting all orders by user id and slice for getting all orders by user id

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/api/apiPaths";
import getCookie from "../../../utils/getCookie";

const initialState = {
  allUserOrders: [],
  pendingOrders: [],
  deliveredOrders: [],
  singleOrderStatus: {},
  orderDetail: {},
  status: "idle",
  loader: false,
};

// create async thunk for getting all orders by user id group by status
export const getAllOrders = createAsyncThunk("order/getAllOrders", async () => {
  const token = getCookie('token');
  const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.ORDER.GET_ALL_ORDERS}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    },
  });
  const ordersJson = await response.json();
  return ordersJson || [];
});

export const getOrderById = createAsyncThunk(
  "order/getOrderById",
  async (orderId: string) => {
    const token = getCookie('token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.ORDER.GET_SINGLE_ORDER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: token }),
      },
      body: JSON.stringify({ orderId }),
    });
    const convertOrderToJson = await response.json();
    return convertOrderToJson || [];
  }
);

// create async thunk for getting order status

export const getOrderStatus = createAsyncThunk(
  "order/getOrderStatus",
  async (orderId: string) => {
const token = getCookie('token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.ORDER.GET_ORDER_STATUS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: token }),
      },
      body: JSON.stringify(orderId),
    });

    const orderStatusJson = await response.json();
    return orderStatusJson || [];
  }
);

// crate order slice with redux thunk middleware
export const OrderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    // set orders
    setOrders: (state, action) => {
      state.allUserOrders = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get all orders
    builder
      .addCase(getAllOrders.pending, (state) => {
        state.status = "loading";
        state.loader = true;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.loader = action.payload.status;
        state.status = "succeeded";
        state.allUserOrders = action.payload.data[0]?.all;
        state.pendingOrders = action.payload.data[0]?.pending;
        state.deliveredOrders = action.payload.data[0]?.delivered;
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.status = "failed";
        state.allUserOrders = [];
        state.loader = false;
      });

    // get order status

    builder
      .addCase(getOrderStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOrderStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleOrderStatus = action.payload;
      })
      .addCase(getOrderStatus.rejected, (state, action) => {
        state.status = "failed";
        state.singleOrderStatus = [];
      });

    // get single order
    builder
      .addCase(getOrderById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orderDetail = action.payload.data;
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.status = "failed";
        state.orderDetail = [];
      });
  },
});

export const { setOrders } = OrderSlice.actions;

export default OrderSlice.reducer;
