import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import useCheckout from "../../hooks/useCheckout";
import useConfig from "../../hooks/useConfig";
import { AddressAccordion } from "../addressAccordion/AddressAccordion";
import { getUserDetails } from "../../appState/componentSlices/userSlice/userLoginSlice";
import { toastMessages } from "../../constant/toastMessage";
import { setCheckoutData } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import LoadingSpinner from "../loader/LoadingSpinner";

const CheckoutForm = () => {
  const { config } = useConfig();
  const dispatch = useAppDispatch();

  const {
    userOtp: {
      userDetails
    }
  } = useAppSelector((state) => state);

  const { orderStatus, checkoutOrderDetails, loader } = useAppSelector(
    ({ checkoutOrder: { orderStatus, checkoutOrderDetails, loader } }) => ({
      orderStatus,
      checkoutOrderDetails,
      loader,
    })
  );

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  const requiredFields = [
    { name: "contactNumber", label: "Contact number", minLength: 10 },
    { name: "preferredDeliverySlot", label: "Time slot" },
    { name: "paymentMethod", label: "Payment method" },
    { name: "currentLocation", label: "Current location", required: false },
    { name: "inputAddress", label: "Address", required: false },
    { name: "addressLandmark", label: "Landmark", required: false },
  ];

  const { checkout } = useCheckout(checkoutOrderDetails, userDetails);

  const handleCheckout = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    const { currentLocation, addressLandmark, inputAddress } = checkoutOrderDetails;
  
    let hasErrors = false;
    let errorMessages = [];
  
    if (!inputAddress || inputAddress.length < 3) {
      hasErrors = true;
      errorMessages.push("Address must be at least 3 characters.");
    }
  
    if (!addressLandmark || addressLandmark.length < 3) {
      hasErrors = true;
      errorMessages.push("Landmark must be at least 3 characters.");
    }
    if(addressLandmark.length < 3 && inputAddress.length < 3){
      hasErrors = true;
      errorMessages.push("Address and Landmark must be at least 3 characters.");
    }
    const missingFields = requiredFields
      .filter((field) => {
        const { minLength: fieldMinLength, name } = field;
        const fieldValue = (checkoutOrderDetails as any)[name];
        if (name === "currentLocation" && (inputAddress || addressLandmark)) {
          return false;
        }
        if (fieldValue) {
          if (fieldMinLength && fieldValue.length < fieldMinLength) {
            return true;
          }
        } else {
          return true;
        }
        return false;
      })
      .map((field) => field.label);


      if (missingFields.length > 0) {
      errorMessages.push(
        `Please enter the following required fields: ${missingFields.join(", ")}.`
      );
      hasErrors = true;
    }
    if (hasErrors) {
      errorMessages.forEach((message) => {
        dispatch(
          setToastMessage({
            type: toastMessages.TYPE.ERROR,
            message,
            show: true,
          })
        );
      });
      return;
    }
  
    checkout();
  };
  
  return (
    <form onSubmit={handleCheckout}>
      {/* {orderStatus && (
        <div className="isOrderPlaced">
          <OrderPlacedMessage />
        </div>
      )} */}
      <div className="checkout-form">
        <AddressAccordion
          checkoutData={checkoutOrderDetails}
          setCheckoutData={setCheckoutData}
          config={config}
        />
      </div>
      <div className={`place-order-button ${loader ? "disabled" : ""}`}>
        {loader && <LoadingSpinner spinner={true} />}
        <button disabled={loader} className="">
          {"Place Order"}
        </button>
      </div>
    </form>
  );
};

export { CheckoutForm };
