interface SearchBarProps {  
  setQuery: (newValue: string) => void;
  query: string
}

const SearchBar: React.FC<SearchBarProps> = ({setQuery, query }) => {

  return (
    <div className="search-input-container">
      <img height="20vh" src="Images/search-line.svg" alt="" />
      <input
        className="search-input-element"
        placeholder="Search"
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
    </div>
  );
};

export { SearchBar };
