import React from 'react'
import { Product } from '../../appState/componentSlices/allProductsFeed'
import { CategoryCard } from '../categoryCard/CategoryCard';
import CategorySmallCard from '../categorySmallCard/CategorySmallCard';
import { ClipLoader } from 'react-spinners';
type SearchCategoryListProps = {
    list: any
    loader: boolean
    CategorysBrands:string
  };

export const SearchCategoryList = (props: SearchCategoryListProps) => {
    const {list, loader, CategorysBrands} = props

    return (
      <div className="search-category-list-container">
        {loader ? (
          <div className="loader-container">
            <ClipLoader color="#27ae60" size={60} />
          </div>
        ) : list?.length ? (
          list?.map((category: any) => (
            <CategorySmallCard key={category._id} category={category} />
          ))
        ) : (
          <div className="no-products-container">
            <p>
              <b>{CategorysBrands} not found</b>
            </p>
          </div>
        )}
      </div>
    );
}

