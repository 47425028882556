import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setCheckoutData } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { Address } from "../../appState/componentSlices/userSlice/@types/User.Initerface";
import { useAppSelector } from "../../appState/reduxTypedHooks";
import Drawer from "../drawer/Drawer";

const AddressDrawer = ({ open, onClose }: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTopShadow, setIsTopShadow] = useState(false);
  const [isBottomShadow, setIsBottomShadow] = useState(false);
  const dispatch = useDispatch();
  const addresses: Address[] = useAppSelector(
    (state) => state.userOtp.userDetails?.addresses
  );
  const username: string = useAppSelector(
    (state) => state.userOtp.userDetails?.name
  );
  const userPhoneNumber: number = useAppSelector(
    (state) => state.userOtp.userDetails?.phone
  );
  const selectedAddressId = useAppSelector(
    (state) => state.checkoutOrder.checkoutOrderDetails?.selectedAddressId
  );
  const onSelectAddress = (addressData: Address) => {
    const { landmark = "", city = "", address = "", _id, contactNumber } = addressData;
    dispatch(
      setCheckoutData({
        addressLandmark: landmark,
        inputAddress: address,
        city,
        selectedAddressId: _id,
        contactNumber: contactNumber || userPhoneNumber
      })
    );
    onClose();
  };
  const updateShadows = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      setIsTopShadow(scrollTop > 0);
      setIsBottomShadow(scrollHeight - 1 > scrollTop + clientHeight);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      updateShadows();
      container.addEventListener("scroll", updateShadows);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateShadows);
      }
    };
  }, [containerRef.current]);
  return (
    <div>
      <Drawer open={open} onClose={onClose}>
        <div className="address-drawer-container">
          <div
            ref={containerRef}
            className={`address-list-container ${
              isTopShadow ? "address-list-top-shadow" : ""
            } ${isBottomShadow ? "address-list-bottom-shadow" : ""}`}
          >
            {addresses?.length > 0 ? (
              addresses.map((addressData, index) => (
                <div
                  key={index}
                  className="address-drawer-checkbox-with-text"
                  onClick={(e) => {
                    e.preventDefault();
                    onSelectAddress(addressData)
                  }}
                >
                  <input
                    type="radio"
                    checked={addressData?._id === selectedAddressId}
                    className="address-drawer-checkbox"
                  />
                  <div className="address-drawer-text-container">
                    <p className="address-drawer-primary-text">
                      {username}
                      {username && addressData.pincode && ","}
                      {addressData.pincode}
                    </p>
                    <p
                      className={`address-drawer-secondary-text ${
                        !username &&
                        !addressData.pincode &&
                        "address-drawer-primary-text"
                      }`}
                    >
                      {addressData?.address} {addressData?.city} 
                      {addressData?.landmark}
                    </p>
                    {addressData?.contactNumber && (
                      <p className="address-drawer-secondary-text">
                        Mob: {addressData?.contactNumber}
                      </p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div>You do not have saved addresses</div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddressDrawer;
