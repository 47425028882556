import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define the initial state for location
interface LocationState {
  currentLocation: string | null;
  deliveryTime: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: LocationState = {
  currentLocation: localStorage.getItem('address'),
  loading: false,
  error: null,
  deliveryTime: localStorage.getItem('deliveryTime')
};

// Async thunk to fetch the current location (latitude, longitude) from the backend
export const fetchCurrentLocation = createAsyncThunk(
  "location/fetchCurrentLocation",
  async (coords: { latitude: number; longitude: number }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/update-location`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(coords),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to get address");
      }
      return data;  // Return the address
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Create the slice for location
const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCurrentLocation.fulfilled, (state, action) => {
        
        if (action.payload.data && localStorage) {
            localStorage.setItem('address', action.payload.data.address);
            localStorage.setItem('deliveryTime', action.payload.data.deliveryTime);
        }
        
        state.loading = false;
        state.currentLocation = action.payload.data.address; // Save address in state
        state.deliveryTime = action.payload.data.deliveryTime;
      })
      .addCase(fetchCurrentLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string; // Capture error message
      });
  },
});

export default locationSlice.reducer;
