interface NavBarProps {
  icon: string;
  heading: string;
  onClick?: ()=>void;
}

const NavBarOptions = (NavBarProps: NavBarProps) => {
  const { icon = "", heading = "" , onClick = ()=>{}} = NavBarProps;
  return (
    <div className="navbar-options" onClick={onClick}>
      <div className="iconContainer">
        <img className="footerIcon userIcon" src={icon} alt="Home_Icon" />
        <div className="home text">{heading}</div>
      </div>
    </div>
  );
};

export { NavBarOptions };
