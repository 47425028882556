import { createAsyncThunk } from "@reduxjs/toolkit";
import getCookie from "../../../utils/getCookie";

interface UpdateOrderPaymentPayload {
  razorpayPaymentId: string;
  razorpayOrderId: string;
  razorpaySignature: string;
  orderId: string;
}

export const updateOrderPayment = createAsyncThunk(
  "checkout/updateOrderPayment",
  async (payload: UpdateOrderPaymentPayload, { rejectWithValue }) => {
    const token = getCookie("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/orders/${payload.orderId}/pay`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            ...(token && { Authorization: token }),
          },
          body: JSON.stringify({
            razorpayPaymentId: payload.razorpayPaymentId,
            razorpayOrderId: payload.razorpayOrderId,
            razorpaySignature: payload.razorpaySignature,
            orderId: payload?.orderId ?? ''
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "Failed to update order payment");
      }

      const data = await response.json();
      return data; // Assuming the response contains updated order details
    } catch (error) {
      return rejectWithValue( "Network error");
    }
  }
);
