import React from 'react';

interface ButtonProps {
  variant?: 'filled' | 'outline';
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  variant = 'filled',
  onClick,
  disabled = false,
  children,
  className
}) => {
  return (
    <button
      className={`button-comp-root button-comp-${variant} ${
        disabled ? 'button-comp-disabled' : ''
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
