import { useEffect } from "react";
import { Accordion, LoadingSpinner, TrackOrder } from "../../components";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { getOrderById } from "../../appState/componentSlices/orderSlice/OrdersSlice";
import { Link } from "react-router-dom";
import { batch } from "react-redux";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
import { setOrderStatus } from "../../appState/componentSlices/checkoutSlice/checkoutSlice";
import { initiateRazorpayPayment } from "../../utils/razorpay";
import { generateWhatsAppMessage } from "../../utils/generateWhatsAppMessage";


function OrderDetail() {
  const whatsapp = "/Images/whatsapp.svg"
  const params = useParams();
  const navigate = useNavigate();
  const { orderId = "" } = params;
  const dispatch = useAppDispatch();
  const { orderDetail, status }: any = useAppSelector((state) => state.orderStatus);
  const customerName = useAppSelector((state) => state.userOtp.userDetails.name);

  const {
    shippingAddress,
    orderNumber = "",
    orderItems = [],
    timeSlot = "",
    totalPayableAmount = "",
    paymentMethod = "",
    orderStatus = [],
    deliveryCharge = "",
    discountAmount = 0,
    paymentStatus,
    _id,
    isPaid,
    distanceData,
  } = orderDetail || {};
  const {
    userOtp: { userDetails },
  } = useAppSelector((state) => state);
  useEffect(() => {
    batch(() => {
      dispatch(setTitle(headers.orderDetail));
      dispatch(getOrderById(orderId));
      dispatch(setDisableFooter(false));
    });
  }, []);
  const handleOnCompleteOrder = async () => {
    initiateRazorpayPayment({ amount: Number(totalPayableAmount), orderId: _id, userDetails, dispatch, navigate });
    setTimeout(async () => {
      await dispatch(setOrderStatus(false));
    }, 2000);
  };
  const handleWhatsAppMessage = () => {
    const messageUrl = generateWhatsAppMessage(customerName ?? "user", orderNumber);
    window.open(messageUrl, '_blank');
  };

  return (
    <div className="order-detail-container">
      {
        orderStatus[orderStatus.length - 1]?.status === 'delivered_successfully' &&
        <div
          onClick={handleWhatsAppMessage}
          className="order-detail-support-container"
        >
          <div className="order-detail-support">

            <div className="order-detail-support-button">
              <img src={whatsapp} alt="" />
            </div>

            <div className="order-detail-support-circle">
              <div className="order-detail-support-circle-text">
                <span>C</span>
                <span>O</span>
                <span>N</span>
                <span>S</span>
                <span>U</span>
                <span>M</span>
                <span>E</span>
                <span>R</span>
                <span>•</span>
                <span>S</span>
                <span>U</span>
                <span>P</span>
                <span>P</span>
                <span>O</span>
                <span>R</span>
                <span>T</span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      }


      {isPaid && paymentStatus || paymentMethod === 'cash' ? (
        (<TrackOrder orderStatus={orderStatus} orderNumber={orderNumber} />)
      ) : isPaid !== undefined && status !== "loading" ? (
        <div className="order-pending-status">
          <h3>Payment pending</h3>
          <button className="complete-payment-button" onClick={handleOnCompleteOrder}>Pay</button>
        </div>) : <LoadingSpinner />
      }
      <div className="order-details-container">

        <Accordion
          heading={`ORDER #${orderNumber}`}
          height={"30vh"}
          showDetails={true}
        >
          <div className="order-detail">
            <div className="address-info-container">
              <div className="address-icon">
                <img src="/Images/location.svg" alt="address icon" />
              </div>
              <div className="address-info">
                <p>Address</p>
                {shippingAddress?.address.includes("https") ? (
                  <Link to={shippingAddress?.address}>
                    {shippingAddress?.address}
                  </Link>
                ) : (
                  <p>{shippingAddress?.address}</p>
                )}
              </div>
            </div>
            {shippingAddress?.currentLocation && (
              <div className="current-location">
                <p>Live Location: </p>
                <p>{shippingAddress?.currentLocation}</p>
              </div>
            )}
            <div className="slot-payment-mode">
              <p>Slot:</p>
              <p>{timeSlot}</p>
            </div>
            <div className="slot-payment-mode">
              <p>Payment Mode:</p>
              <p>{paymentMethod}</p>
            </div>
            <div className="order-payment-info">
              <p>Discount Amount:</p>
              <p>{discountAmount}</p>
            </div>
            <div className="order-payment-info">
              <p>Delivery Charges:</p>
              <p>{deliveryCharge}</p>
            </div>
            <div className="order-payment-info">
              <p>Total Amount:</p>
              <p>{totalPayableAmount}</p>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="order-items-container">
        <Accordion heading="Description" height={"30vh"} showDetails={true}>
          <div>
            {orderItems?.length ? (
              orderItems.map((item: any) => {
                const { product, quantity, price } = item;
                
                return (
                  <div className="child-item-container" key={product.itemName}>
                    <div className="item-image-container">
                      <img
                        src={
                          product?.images?.length
                            ? product?.images?.[0]?.secureUrl
                            : ""
                        }
                        alt="Item Image"
                      />
                    </div>
                    <div className="item-info-container">
                      <p className="item-name">
                        {product.itemName} &nbsp; <span>({quantity})</span>
                      </p>
                     {product?.itemSellingPricePerUnit && <p className="item-price">{product?.itemSellingPricePerUnit} Rs.</p>}
                      <p className="item-total">Total : {price}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default OrderDetail;
