import { Product, setProductIdPDP } from "../../appState/componentSlices/allProductsFeed";
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from '../../appState/reduxTypedHooks';

type ProductDropdownCardProps = {
    product: Product;
};

const ProductDropdownCard = (props: ProductDropdownCardProps) => {
    const { product } = props;
    const productId = product._id;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();

    const openProductDescriptionPage = () => {
        if (productId) {
            dispatch(setProductIdPDP(productId));
            if (pathname !== "/product-description") {
                navigate("/product-description", { state: { productId } });
            }
        }
    }

    const { images = [], itemName = "" } = product;
    const productImage = images[0]?.secureUrl || "";

    return (
        <div
            className="product-dropdown-card"
            onClick={openProductDescriptionPage}
        >
            <div className="product-dropdown-card-main">
                <div className="product-dropdown-card-image-cover">
                    <img
                        className="product-dropdown-card-image"
                        src={productImage}
                        alt=""
                    />
                </div>
                <div className="product-dropdown-card-info">
                    <div className="product-dropdown-card-name">
                        {itemName}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDropdownCard