import "./AccountDeletionSuccess.scss";

export const AccountDeletionSuccess = () => {
  return (
    <div className="success-container">
      <div className="success-message">
        <h1>Account Deletion Request Submitted</h1>
        <p>
          Thank you for submitting your account deletion request. Our team has
          received your request and will process it within 30 days.
        </p>
        <p>
          Please check your phone number for further updates or instructions. If you
          have any questions or concerns, feel free to contact our support team.
        </p>
        <a
          href="mailto:contact@priyaminnovations.com"
          className="support-link"
        >
          Send Mail
        </a>
      </div>
    </div>
  );
};
