import { useEffect, useState } from "react";
import {
  getPopularProducts,
  getProductById,
  getSimilarProducts,
} from "../../appState/componentSlices/allProductsFeed";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { Card, ProductCard } from "../../components";
import "../../styles/index.scss";
import useCart from "../../hooks/useCart";
import { getProductInCart } from "../../appState/componentSlices/cartSlice/ProductInCartSlice";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { getPriceBySlab } from "../../utils/getPriceBySlab";
import { setModal } from "../../appState/componentSlices/userSlice/userLoginSlice";

export const ProductDescriptionPage = () => {
  const [quantity, setQuantity] = useState(1);
  const [prevQuantity, setPrevQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState("");
  const { addToCart, cart } = useCart();
  const cartProducts = cart.productInCart?.products ?? [];
  const incrementQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 0) setQuantity((prev) => prev - 1);
  };

  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    productInfo,
    similarProducts,
    productIdFromState,
    isLoading = false,
    cartLoading = false,
    isLoggedIn = false,
  } = useAppSelector((state) => ({
    similarProducts: state.ProductsData.similarProducts,
    productInfo: state.ProductsData.productInfo,
    productIdFromState: state.ProductsData.productId,
    isLoading: state.ProductsData.loader,
    cartLoading: state.productInCart.loader,
    isLoggedIn: state.userOtp.isLoggedIn,
  }));
  const productId = productIdFromState ? productIdFromState : state?.productId;

  const {
    itemName,
    images,
    itemMRPperUnit,
    itemSellingPricePerUnit = 0,
    quantityUnitName,
    itemPerUnitQuantity,
    slabPricing = [],
    itemStockQuantity,
    itemCategory
  } = (productInfo as any) ?? {};
  const pdpBannerImage = images?.at(-1)?.secureUrl;

  const handleAddToCart = async () => {
    if (!isLoggedIn) {
      return dispatch(setModal(true));
    }
    await addToCart({
      product: { productDetails: structuredClone(productInfo) },
      quantity: quantity + prevQuantity,
    });
    navigate("/cart");
  };

  useEffect(() => {
    productId && dispatch(getProductById(productId));
  }, [productIdFromState]);

  useEffect(() => {
    const product = cartProducts?.find(
      (product: any) => product?.productDetails?._id === productId
    );
    const newQuantity = product?.productQuantity;
    if (newQuantity) {
      setPrevQuantity(newQuantity);
    } else {
      setPrevQuantity(0);
    }
  }, [cart, productIdFromState]);

  useEffect(() => {
    if(isLoggedIn)(async () => await dispatch(getProductInCart("")))();
    if ( similarProducts && similarProducts.length <= 0 && itemCategory || similarProducts?.[0]?.itemCategory !== itemCategory) {
      dispatch(getSimilarProducts({ limit: 10, itemCategory: itemCategory  }));
    }
  }, [productInfo]);

  useEffect(() => {
    let price = (itemSellingPricePerUnit * quantity).toFixed(2);
    if(slabPricing.length > 0) {
      price = getPriceBySlab(slabPricing, quantity);
    }
    setTotalPrice(price);
  }, [quantity, itemSellingPricePerUnit, slabPricing]);

  if (isLoading) {
    return (
      <div className="LoadingSpinner">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="product-page">
      <div className="product-header">
        <button className="back-button" onClick={() => navigate(-1)}>
          ←
        </button>
        {pdpBannerImage && (
          <img src={pdpBannerImage} alt="Product" className="product-image" />
        )}
      </div>

      <div className="product-info">
        <h1 className="product-title">{itemName}</h1>
        <p className="product-subtitle">
          &#x20B9;{itemSellingPricePerUnit} Per{" "}
          {itemPerUnitQuantity === 1 ? "" : itemPerUnitQuantity ?? ""}{" "}
          {quantityUnitName}
        </p>
        <div className="product-details">
          <span className="price-title">Price:</span>
          {itemMRPperUnit > itemSellingPricePerUnit && (
            <del className="mrp-price">&#x20B9;{itemMRPperUnit}</del>
          )}
          <span className="selling-price">
            &#x20B9;{itemSellingPricePerUnit}
          </span>
        </div>

        <div className="quantity-controls">
          <button
            onClick={decrementQuantity}
            className="quantity-btn"
            disabled={quantity === 0}
          >
            -
          </button>
          <span className="quantity">{quantity}</span>
          <button onClick={incrementQuantity} className="quantity-btn">
            +
          </button>
        </div>
      </div>
      {prevQuantity > 0 && (
        <div className="similar-products">
          <h2>Quantity in Cart: {prevQuantity}</h2>
        </div>
      )}
      {Boolean(slabPricing.length) && (
        <div className="slab-info">
          <h1 className="slab-title">Slab Pricing</h1>
          <table className="slab-table">
            <thead>
              <tr>
                <th>Quantity</th>
                <th className="price-align">Price</th>
              </tr>
            </thead>
            <tbody>
              {slabPricing.map(
                (slabs: number[], idx: number, arr: number[][]) => {
                  return (
                    <tr key={idx}>
                      <td>{`
                          ${slabs[1]}  ${
                        arr[idx + 1] ? `- ${arr[idx + 1][1] - 1}` : "+"
                      }`}</td>
                      <td className="price-align">{slabs[2]}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      )}
      <div className="similar-products">
        <h2>Similar products</h2>
        <div className="product-carousel">
          {similarProducts && similarProducts?.map((product: any, index: number) => (
            <div>
              <Card key={index}>
                <ProductCard product={product} isSlab={false} />
              </Card>
            </div>
          ))}
        </div>
      </div>

      <div className="add-to-cart">
        {cartLoading && <LoadingSpinner spinner />}
        <div className="add-to-cart-content">
          <div className="cart-price">
            <p>Total:</p>
            <p className="price">&#x20B9;{totalPrice}</p>
          </div>
          <button
            className="cart-button"
            onClick={handleAddToCart}
            disabled={cartLoading || quantity <= 0}
          >
            {cartLoading ? "Adding" : "Add to cart"}
          </button>
        </div>
      </div>
    </div>
  );
};
