import { useEffect, useState } from "react";
import { Product, setProductIdPDP } from "../../appState/componentSlices/allProductsFeed";
import { CartQuantityButton } from "../cartQuantityButton/CartQuantityButton";
import { Accordion } from "../accordion/Accordion";
import { useSelector } from "react-redux";
import { RootState } from "../../appState/store";
import { useAppDispatch } from "../../appState/reduxTypedHooks";
import { useLocation, useNavigate } from "react-router";
type ProductCardProps = {
  product: Product;
  isSlab?: boolean
};
function ProductCard(props: ProductCardProps) {
  const { product, isSlab = true } = props;
  const productsInCart = useSelector((state: RootState) => state.productInCart.productInCart.products || []);
  const productId = product._id;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const openProductDescriptionPage = () => {
    if(productId) {
      dispatch(setProductIdPDP(productId));
      if(pathname !== "/product-description"){
        navigate("/product-description", { state: { productId }});
      } 
    }
  }

  const productCounterHandler = () => {
    for (const cartProduct of productsInCart) {
      const { productQuantity, productDetails } = cartProduct
      if (product._id === productDetails._id) {
        return (productQuantity);
      }
    }
    return 0
  };

  const { images = [] } = product;


  const productImage = images[0]?.secureUrl || "";


  const {
    itemName = "",
    itemMRPperUnit = 0,
    itemSellingPricePerUnit = 0,
  } = product;

  const [productQuantity, setProductQuantity] = useState(productCounterHandler());
  const [mrp, setMrp] = useState(itemSellingPricePerUnit);
  const Product = {
    productDetails: product,
  };
  const { slabPricing } = product;
  const [productCopy, setProductCopy] = useState<any>(structuredClone(Product));

  // mrp set when quantity changes with respect to slab
  useEffect(() => {
    let index = 0;
    for (const slab of slabPricing) {
      if (
        productQuantity <= slabPricing[index + 1]?.[1] - 1 ||
        !slabPricing[index + 1]
      ) {
        setProductCopy((prev: { productDetails: any }): any => {
          prev.productDetails.itemSellingPricePerUnit = slab[2];
          return prev;
        });
        setMrp(slab[2]);
        break;
      }
      index++;
    }
  }, [productQuantity]);
  return (
    <div className="product-container" onClick={openProductDescriptionPage}>
      <div className="product-image-btn-container">
        <div className="product-image-container">
          <img
            className="product-image"
            src={productImage || ""}
            alt={itemName}
          />
        </div>
        {product && (
          <CartQuantityButton
            quantity={productQuantity}
            showAddButton={true}
            product={productCopy}
            setProductQuantity={setProductQuantity}
          />
        )}
      </div>



      <div className="product-info">
        <div>
          <div className="product-name">
            {itemName?.length > 15 ? itemName?.slice(0, 15) + "..." : itemName}
          </div>
        </div>
        <div className="mrp-sp-container">
          <del className="mrp-price product-mrp">&#x20B9;{itemMRPperUnit}</del>
          <span className="selling-price product-sp">&#x20B9;{itemSellingPricePerUnit}</span>
        </div>

        {
          isSlab && (
           Boolean(product?.slabPricing.length) && (
              <div className="slap-accordion">
                <Accordion
                  heading="Slab Pricing"
                  headingColor="green"
                >
                  <table className="salb-table" width="100px">
                      <thead>
                        <tr>
                          <th>Qty</th>
                          <th className="price-align">Price</th>
                        </tr>
                      </thead>

                      <tbody>
                        {product?.slabPricing.map(
                          (slabs: number[], idx: number, arr: number[][]) => {
                            return (
                              <tr key={idx}>
                                <td>{`
                                        ${slabs[1]}  ${arr[idx + 1] ? `- ${arr[idx + 1][1] - 1}` : "+"
                                  }`}</td>
                                <td className="price-align">{slabs[2]}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                </Accordion>
              </div>
            )
          )
        }
      </div>
    </div>
  );
}

export { ProductCard };
