import { Card } from "../card/Card";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import {
  getAllProducts,
  Product,
} from "../../appState/componentSlices/allProductsFeed";
import { ProductCard } from "../productCard/ProductCard";
import { Accordion } from "../accordion/Accordion";
import { CategoryAccordion } from "../categoryAccordion/CategoryAccordion";
import LoadingSpinner from "../loader/LoadingSpinner";
import { PopularAccordion } from "../popularAccordion/PopularAccordion";
function ProductsListCard() {
  const loader = useAppSelector((state) => state.ProductsData.plpLoader);
  // const productsByBrand =
  //   useAppSelector((state) => state.ProductsData.allProducts?.slice(0, 10)) ||
  //   [];
  const categories = useAppSelector((state) => state.ProductsData.categories);
  const brands = useAppSelector((state) => state.ProductsData.brands);
  const PopularProducts = useAppSelector((state)=> state.ProductsData.popularProducts)


  return (
    <div>
      {loader ? (
        <div className="spinner-container-main">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="popular-accordian">
            <PopularAccordion
              products={PopularProducts}
              type= "Best seller"
              subHeadingText= "See All"
              length={PopularProducts.length}
              subHeadingLink="/popular-products"
            />

          </div>
          <div className="category-accordian">
            <CategoryAccordion
              categories={categories}
              length={categories.length}
              type={"Categories"}
            />
          </div>
          <div className="brand-accordian">
            <CategoryAccordion
              categories={brands}
              type={"brands"}
              length={brands?.length}
            />
          </div>
        </>
      )}
    </div>
  );
}

export { ProductsListCard };
