import React, { useState, Ref, useEffect, useRef } from "react";
import { Accordion } from "../accordion/Accordion";
import { SearchBar } from "../searchBox/SearchBar";
import { UserAvatar } from "../userAvatar/UserAvatar";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import {
  logoutUser,
  setModal,
} from "../../appState/componentSlices/userSlice/userLoginSlice";
import { getProductInCart } from "../../appState/componentSlices/cartSlice/ProductInCartSlice";
import { toastMessages } from "../../constant/toastMessage";
import { useLocation, useNavigate } from "react-router";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import { firebaselogOut } from "../../utils/userAuth";
import Avatar from "../avatar/Avatar";
import { SearchProductList } from "../searchProductList/SearchProductList";
import { useSelector } from "react-redux";
import { RootState } from "../../appState/store";
import useDebounce from "../../utils/debounce";
import {
  SearchProductsByCategorysOrBrands,
  clearSearchedCategorys,
  clearSearchedProducts,
  searchCategorys,
  searchProductsByName,
} from "../../appState/componentSlices/allProductsFeed";
import { SearchCategoryList } from "../searchCategoryList/SearchCategoryList";
import { setIsSearch } from "../../appState/componentSlices/searchslice";
import { fetchCurrentLocation } from "../../appState/componentSlices/locationSlice/locationSlice";

type NavbarProps = {
  profileRef: Ref<HTMLDivElement> | undefined;
  showProfileBar?: boolean;
  setShowProfileBar?: (state: boolean) => void;
};
const NavBar = (props: NavbarProps) => {
  const { pathname } = useLocation();
  if(pathname === "/product-description"){
    return <></>
  }
  const {
    profileRef,
    showProfileBar = false,
    setShowProfileBar = (state: boolean) => {},
  } = props;
  const {
    userOtp: { isLoggedIn },
    util: { title },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const categories = useAppSelector(
    (state) => state.ProductsData.categories || []
  );
  const brands = useAppSelector((state) => state.ProductsData.brands || []);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const isCheckoutPage = location.pathname === "/checkout";
  const isProfilePage = location.pathname === "/profile";
  const isCartPage = location.pathname === "/cart";
  const isOrderPage = location.pathname === "/allOrders";
  const isOrderDetailsPage = location.pathname.includes('order');

  const searchedProducts = useSelector(
    (state: RootState) => state.ProductsData.searchedProducts || []
  );
  const searchedCategorysBrands = useSelector(
    (state: RootState) => state.ProductsData.searchedCategorysBrands || []
  );
  const loader = useSelector((state: RootState) => state.ProductsData.loader);
  const [parsedPath, setParsedPath] = useState<{ type: string; _id?: string }>({
    type: "",
  });
  const { type, _id } = parsedPath;
  const [isProductList, setIsProductList] = useState(true);
  const [isDropdown, setIsDropdown] = useState(false)
  const [query, setQuery] = useState("");
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("asc");

  function parsePath(path: string) {
    if (!path || path === "/") {
      return { type: "", _id: "" };
    }

    const decodedPath = decodeURIComponent(path);

    const segments = decodedPath.split("/").filter(Boolean);

    if (segments.length === 1) {
      return { type: segments[0].toLowerCase(), _id: "" };
    }

    const result: { type: string; _id?: string } = {
      type: (segments[0] || "").toLowerCase(),
    };

    if (segments.length === 2) {
      result._id = (segments[1] || "").toLowerCase();
    }

    return result;
  }

  const logout = async () => {
    try {
      await dispatch(logoutUser());
      sessionStorage.clear();
      firebaselogOut();
      dispatch(
        setToastMessage({
          type: toastMessages.TYPE.SUCCESS,
          message: toastMessages.MESSAGE.SIGNED_OUT,
          show: true,
        })
      );
      await dispatch(getProductInCart(""));
    } catch (error) {
      console.error(error);
    }
  };

  const login = () => {
    dispatch(setModal(true));
  };

  const avatarProps = {
    isLoggedIn,
    profileRef,
    showProfileBar,
    setShowProfileBar,
    logout,
    login,
  };

  const debouncedProductsSearch = useDebounce((input: string) => {
    if (query.trim().length > 0) {
      dispatch(
        searchProductsByName({
          itemName: query.trim(),
          limit,
          order,
        })
      );
    }
  }, 300);

  const debouncedCategorysOrBrandsProductsSearch = useDebounce(
    (input: string) => {
      if (query.trim().length > 0) {
        dispatch(
          SearchProductsByCategorysOrBrands({
            itemName: query.trim(),
            limit,
            order,
            categorysOrBrands: type,
            categorysOrBrandsName: _id ? _id : "",
          })
        );
      }
    },
    300
  );

  const handleCategoriesNameSearch = (query: string) => {
    const filteredCategories = categories?.filter((category) => {
      const words = category.name.trim().toLowerCase().split(/\s+/);
      return words.some((word: string) =>
        word.startsWith(query.trim().toLowerCase())
      );
    });
    dispatch(searchCategorys(filteredCategories));
  };

  const handleBrandsNameSearch = (query: string) => {
    const filteredCategories = brands?.filter((brand) => {
      if (!brand.name || typeof brand.name !== "string") return false;
      const normalizedId = brand.name.trim().replace(/\t+/g, " ").toLowerCase();
      const words = normalizedId.split(/\s+/);
      return words.some((word) => word.startsWith(query.trim().toLowerCase()));
    });
    dispatch(searchCategorys(filteredCategories));
  };

  const handleSearch = (event: string) => {
    switch (type) {
      case "brands":
        if (_id) {
          setIsProductList(true);
          setIsDropdown(false);
          debouncedCategorysOrBrandsProductsSearch(event);
        } else {
          setIsProductList(false);
          handleBrandsNameSearch(event);
          setIsDropdown(true)
        }
        break;
      case "categories":
        if (_id) {
          setIsProductList(true);
          setIsDropdown(false);
          debouncedCategorysOrBrandsProductsSearch(event);
        } else {
          setIsProductList(false);
          handleCategoriesNameSearch(event);
          setIsDropdown(true)
        }
        break;
      default:
        setIsProductList(true);
        debouncedProductsSearch(event);
        setIsDropdown(true)
        break;
    }
  };

  useEffect(() => {
    if (query.length === 0) {
      dispatch(clearSearchedProducts());
      dispatch(clearSearchedCategorys());
      dispatch(setIsSearch(false));
      setIsDropdown(false)
      if (dropdownRef.current) {
        dropdownRef.current.classList.remove("open");
      }
    }
    if (query.length) {
      handleSearch(query);
      dispatch(setIsSearch(true));
      if (dropdownRef.current) {
        dropdownRef.current.classList.add("open");
      }
    }
  }, [query]);

  useEffect(() => {
    setQuery("");
    dispatch(clearSearchedProducts());
    dispatch(clearSearchedCategorys());
    const result = parsePath(location.pathname);
    setParsedPath(result);
    setIsDropdown(false);
  }, [location.pathname]);

  const [isAddressVisible, setIsAddressVisible] = useState(false);

  const { currentLocation, loading, error, deliveryTime } = useSelector(
    (state: RootState) => state.location
  );

  // Function to get stored coordinates from localStorage
  const getStoredLocation = () => {
    const storedLatitude = localStorage.getItem("latitude");
    const storedLongitude = localStorage.getItem("longitude");
    const currentAdress = localStorage.getItem('address');
    return {
      latitude: storedLatitude ? parseFloat(storedLatitude) : null,
      longitude: storedLongitude ? parseFloat(storedLongitude) : null,
      currentAdress: currentAdress ?? null
    };
  };

  // Function to store the coordinates in localStorage
  const storeLocation = (latitude: number, longitude: number) => {
    localStorage.setItem("latitude", latitude.toString());
    localStorage.setItem("longitude", longitude.toString());
  };

  const handleGetCurrentLocation = () => {
    // Use Geolocation API to get the user's coordinates
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      // Get the stored coordinates from localStorage
      const storedLocation = getStoredLocation();

      // If the coordinates have changed, dispatch the action to get the address
      if (
        latitude !== storedLocation.latitude ||
        longitude !== storedLocation.longitude
      ) {
        // Dispatch action to fetch the address from the backend
        dispatch(fetchCurrentLocation({ latitude, longitude }));

        // Store the new coordinates in localStorage
        storeLocation(latitude, longitude);
      }
    });
  };

  const handleResyncLocation = () => {
    // Logic to trigger resync only if the location has changed
    console.log("Resyncing location...");

    // Use Geolocation API to get the user's coordinates
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      // Get the stored coordinates from localStorage
      const storedLocation = getStoredLocation();
  
      // Only fetch the address if the coordinates have changed
      if (
        latitude !== storedLocation.latitude ||
        longitude !== storedLocation.longitude || 
        !storedLocation.currentAdress
      ) {
        dispatch(fetchCurrentLocation({ latitude, longitude }));

        // Store the new coordinates in localStorage
        storeLocation(latitude, longitude);
      } 
    });
  };

  useEffect(()=> {
    const storedLocation = getStoredLocation();
    if (!storedLocation.latitude) {
      
      handleGetCurrentLocation()
    }
  }, [])

const isCurrectAddressVisible = !isCheckoutPage && !isProfilePage && !isCartPage && !isOrderDetailsPage;
const isNavTitleVisible = isCheckoutPage || isProfilePage || isCartPage || isOrderDetailsPage;

const addTwentyMinutes = (deliveryTime: string) => {
  const timeParts = deliveryTime.split(" "); 
  const minutes = parseInt(timeParts[0], 10);
  const newTime = minutes + 20; 
  return newTime;
};

  return (
    <>
      <div
        className={`nav-container ${(isCheckoutPage || isCartPage) && "container-on-checkout"}`}
      >
        <div className="nav-container-main">
          <div className="nav-container-address-section">
        {isCurrectAddressVisible && !isAddressVisible && (
        <div className="location-info">
          {currentLocation ? (
            <>
              {deliveryTime && (
                <div className="delivery-time-container">
                  <p>Delivery in</p>
                  <p className="delivery-time">{addTwentyMinutes(deliveryTime)} minutes</p>
                </div>
              )}
              <div className="current-address-container">
                <p className="current-address-text">{currentLocation}</p>
                <span
                  className={`resync-icon ${loading ? "resync-icon-loader" : ""}`}
                  onClick={handleResyncLocation}
                  title="Resync Location"
                >
                  &#x21bb;
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="delivery-time-container">
                  <p>Delivery in</p>
                  <p className="delivery-time">20 minutes</p>
                </div>
                <div className="current-address-container">
                <p className="current-address-text"
                onClick={handleResyncLocation}
                >
                Add Your Current Location
              </p>
              <span
                  className={`resync-icon ${loading ? "resync-icon-loader" : ""}`}
                  onClick={handleResyncLocation}
                  title="Resync Location"
                >
                  &#x21bb;
                </span>
              </div>
            </>
          )}
        </div>
      )}
      {isCurrectAddressVisible &&  <Avatar {...avatarProps} />}
        </div>
        </div>
       { isCurrectAddressVisible && <SearchBar setQuery={setQuery} query={query} />}
       {isNavTitleVisible && <div
          className={`nav-header-top ${
            title.toLowerCase() == "home" && `header-container`
          }`}
        >
          {title.toLowerCase() !== "home" ? (
            <button
              className="back-btn"
              onClick={() =>
                location.pathname.includes("/order")
                  ? navigate("/")
                  : navigate(-1)
              }
            >
              <div className="arrow-left"></div>
            </button>
          ) : (
            false
          )}
          <p className="heading-position">{title !== "Home" && title}</p>
          <div></div>
        </div>}
        {isCheckoutPage && deliveryTime && (
                <div className="delivery-time-container-on-checkout">

                  <p className="delivery-time">Your order will be delivered in {addTwentyMinutes(deliveryTime)} minutes</p>
                </div>
              )}
        {!isCheckoutPage && !isProfilePage && (
          <div className="nav-header-bottom">
          </div>
        )}

        {(
        <div ref={dropdownRef} className="scarch-display-container">
          {!isProductList ?(
            <SearchCategoryList
              loader={loader}
              list={searchedCategorysBrands}
              CategorysBrands={type}
            />
          ):(
            <SearchProductList
              loader={loader}
              products={searchedProducts}
            />
          )}
        </div>
        )}
      </div>
    </>
  );
};

export { NavBar };
