import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./appState/store";
import App from "./App";
import {
  Cart,
  CategoryPage,
  CheckoutPage,
  Home,
  Privacy,
  ProductList,
  Profile,
  TrackOrderPage,
  TermsAndConditions,
  PrivacyPolicy,
  SearchProduct,
  PopularProductList,
  RequestDeletion,
  AccountDeletion,
  AccountDeletionSuccess,
  ProductDescriptionPage
} from "./pages";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RequireAuth } from "./components";
import { OrderList } from "./pages/orderList/OrderList";
import OrderDetail from "./pages/OrderDetail/OrderDetail";
import { RedirectIncorrectPath } from "./components/redirectIncorrectPath/RedirectIncorrectPath";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>error occurred in App</div>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/profile",
        element: [
          <RequireAuth>
            <Profile />
          </RequireAuth>,
        ],
      },
      // Future use
      // {
      //   path: "/privacy",
      //   element: <Privacy />,
      // },
      {
        path: "/cart",
        element: [
          <RequireAuth>
            <Cart />
          </RequireAuth>,
        ],
        errorElement: <div>error occurred in Cart</div>,
      },
      {
        path: "/checkout",
        element: [
          <RequireAuth>
            <CheckoutPage />
          </RequireAuth>,
        ],
        errorElement: <div>error occurred in Checkout</div>,
      },
      {
        path: "/trackOrder",
        element: [
          <RequireAuth>
            <TrackOrderPage />
          </RequireAuth>,
        ],
        errorElement: <div>error occurred in TrackOrder</div>,
      },
      {
        path: "/:id",
        element: [
          <RedirectIncorrectPath>
            <CategoryPage />
          </RedirectIncorrectPath>,
        ],
      },
      {
        path: "/:id/:category",
        element: [
          <RedirectIncorrectPath>
            <ProductList />
          </RedirectIncorrectPath>,
        ],
      },
      {
        path: "/allOrders",
        element: [
          <RequireAuth>
            <OrderList />
          </RequireAuth>,
        ],
      },
      {
        path: "/order/:orderId",
        element: [
          <RequireAuth>
            <RedirectIncorrectPath>
              <OrderDetail />
            </RedirectIncorrectPath>
          </RequireAuth>,
        ],
        errorElement: <div>error occurred in TrackOrder</div>,
      },
      {
        path: "/search-products",
        element: <SearchProduct/>  
      },
      {
        path: "/popular-products",
        element: <PopularProductList/>  
      },
      {
        path: "/product-description",
        element: <ProductDescriptionPage/>  
      },
    ],
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/account-deletion",
    element: [
      <RequireAuth>
        <AccountDeletion />
      </RequireAuth>,
    ],
    errorElement: <div>Error occurred in Account Deletion</div>,
  },
  {
    path: "/request-deletion",
    element: [
      <RequireAuth>
        <RequestDeletion />
      </RequireAuth>,
    ],
    errorElement: <div>Error occurred in Account Deletion Request</div>,
  },
  {
    path: "/account-deletion-success",
    element: <AccountDeletionSuccess />,
    errorElement: <div>Error occurred in Account Deletion Request</div>,
  },
 
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Register the service worker
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js')
//       .then((registration) => {
//         console.log('Service Worker registered with scope:', registration.scope);

//         // Optional: Request permission for Push Notifications
//         if (Notification.permission === 'default') {
//           Notification.requestPermission().then((permission) => {
//             if (permission === 'granted') {
//               console.log('Notification permission granted.');
//               // TODO: Subscribe the user to push notifications here
//             }
//           });
//         }
//       })
//       .catch((error) => {
//         console.error('Service Worker registration failed:', error);
//       });
//   });
// }


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
