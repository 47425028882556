const setCookie = (
  cookieKey: string,
  value: string,
  path: string = "/" 
) => {
  // Set cookie with no expiration (session cookie)
  document.cookie = `${cookieKey}=${value}; path=${path}`;

  // Store the value in localStorage as a fallback
  localStorage.setItem(cookieKey, value);
};

export default setCookie;