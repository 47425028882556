type modal = {
  showModal: boolean;
  children: React.ReactNode;
};

import React from "react";
// import "../../styles/index.scss"
// import "../components/index.scss"
import "./Modal.scss";

const Modal: React.FC<modal> = ({ children, showModal }) => {
  return (
    <div
      className="modal-container"
      style={{ display: `${showModal ? "flex" : "none"}` }}
    >
      {children}
    </div>
  );
};

export { Modal };
