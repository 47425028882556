// create a card component that will be used to display the image or title in the accordion

import { Link, useParams } from "react-router-dom";
import { CategoriesImages } from "../../constant/CategoriesImages";

const CategoryCard = (props: any) => {
  const { category } = props;
  const params = useParams();
  const categoryType = props.type || params.id;  

  return (
    <Link
      className="category-page"
      to={"/" + `${categoryType}` + "/" + `${category?.name}`}
    >
      <div className="category-card-main">
        {Boolean(category) && (
          <>
            <div className="category-card">
              <img
                className="category-card-image"
                src={CategoriesImages[category?.name] || category?.image?.secureUrl}
                alt=""
              />
            </div>
            <div className="cart-item-info">
              <div className="cart-item-name">{category?.name}</div>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export { CategoryCard };
