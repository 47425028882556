import { Link, useLocation } from "react-router-dom";
import { CheckoutButton } from "../checkoutButton/CheckoutButton";

type CartCheckoutProps = {
  cartTotalSP: number;
};

const CartCheckout = ({ cartTotalSP }: CartCheckoutProps) => {
  const { pathname } = useLocation();
  if(pathname === "/product-description"){
    return <></>
  }
  return (
    <div className="cart-checkout">
      {cartTotalSP > 0 ? (
        <>
          <div className="cart-checkout-total">Total: ₹ {cartTotalSP}</div>
          <div className="cart-checkout-button">
            <Link to="/checkout">
              <CheckoutButton />
            </Link>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export { CartCheckout };
