import { useEffect } from "react";
import { useAppDispatch } from "../../appState/reduxTypedHooks";
import { ProductsListCard } from "../../components/productsListCard/ProductsListCard";
import {
  setDisableFooter,
  setTitle,
} from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";
import { batch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../appState/store";

const Home = () => {
  
  const token = useSelector((state: RootState) => state.token.token);

  // get details from verify state and show otp modal
  const dispatch = useAppDispatch();
  useEffect(() => {
    batch(() => {
      dispatch(setTitle(headers.home));
      dispatch(setDisableFooter(false));
    });
  }, [token]);
  return (
    
        <ProductsListCard />

  );
};

export { Home };
