import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import getCookie from '../../utils/getCookie';

interface TokenState {
    token: string | null | undefined;
}

const initialState: TokenState = {
    token: getCookie('token'),
};

export const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload;
        },
        clearToken(state) {
            state.token = null;
        },
    },
});

export const { setToken, clearToken } = tokenSlice.actions;

