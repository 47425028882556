import useConfig from "../../hooks/useConfig";
import { Accordion } from "../accordion/Accordion";
import { ContactAccordion } from "../contactAccordion/ContactAccordion";
import { PaymentAccordion } from "../paymentAccordion/PaymentAccordion";
import { TimeSlotAccordion } from "../timeSlotAccordion/TimeSlotAccordion";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import AddressDrawer from "../addressDrawer/AddressDrawer";
import { useState } from "react";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import { setToastMessage } from "../../appState/componentSlices/utilSlice/utilSlice";
import { toastMessages } from "../../constant/toastMessage";
import { fetchCurrentLocation } from "../../appState/componentSlices/locationSlice/locationSlice";

export type AddressAccordionProps = {
  checkoutData: any;
  setCheckoutData: any;
  config: any;
};

function AddressAccordion({
  checkoutData,
  setCheckoutData,
}: AddressAccordionProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { config } = useConfig();
  const dispatch = useAppDispatch();
  const currentLocation = useAppSelector(
    ({ checkoutOrder: { checkoutOrderDetails } }) =>
      checkoutOrderDetails?.currentLocation
  );
  const googleMapLink = "https://www.google.com/maps/place/";
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const input = value;
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: input.length <= 3 && input.length > 0,
    }));
 
  
    dispatch(
      setCheckoutData({
        [name]: input, 
      })
    );
  }
  const customerLiveLocation = (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          setCheckoutData({
            currentLocation:
              googleMapLink +
              position.coords.latitude +
              "+" +
              position.coords.longitude,
          })
        );
        dispatch(fetchCurrentLocation({latitude: position.coords.latitude, longitude: position.coords.longitude}))
      });
      dispatch(
        setToastMessage({
          type: toastMessages.TYPE.SUCCESS,
          message: toastMessages.MESSAGE.CUSTOMER_LOCATION_SUCCESS,
          show: true,
        })
      );
    } catch (error) {
      dispatch(
        setToastMessage({
          type: toastMessages.TYPE.ERROR,
          message: toastMessages.MESSAGE.CUSTOMER_LOCATION_FAILED,
          show: true,
        })
      );
    }
  };
  return (
    <Accordion heading={"Address"} showDetails={true}>
      <div className="address-accordion-container">
        <div className="address-form">
          <div className="address-field-container">
            <p className="address-heading">
              Address <sup className="required-field">*</sup>{" "}
            </p>
            <input
              type="text"
              className="address-input"
              name="inputAddress"
              id="inputAddress"
              placeholder="Enter Address"
              onChange={handleInputChange}
              value={checkoutData.inputAddress}
            />
            {checkoutData.inputAddress && errors.inputAddress && (
              <p className="error-message">
                Address must be more than 3 characters.
              </p>
            )}
          </div>

          <div className="address-field-container">
            {" "}
            <p className="address-heading">
              Landmark <sup className="required-field">*</sup>{" "}
            </p>{" "}
            <input
              name="addressLandmark"
              id="addressLandmark"
              className="address-input"
              type="text"
              placeholder="Enter Landmark"
              onChange={handleInputChange}
              value={checkoutData.addressLandmark}
            />{" "}
            {checkoutData.addressLandmark && errors.addressLandmark && (
              <p className="error-message">
                Landmark must be more than 3 characters.
              </p>
            )}
          </div>
          <div className="address-accordion-btn-container">
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault();
                setDrawerOpen(true);
              }}
            >
              saved addresses
            </Button>
            <div className="location-container">
              <Button
                className="location-button"
                // type="button"
                variant="outline"
                onClick={!currentLocation ? customerLiveLocation : (e) => { 
                  e.preventDefault();
                  window.open(currentLocation, "_blank");
                }}
              >
                <img
                  className="location-icon"
                  src="/Images/location.svg"
                  alt=""
                />{" "}
                {currentLocation ? (
                  <p className="location-text">View location</p>
              ) : "current location"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ContactAccordion
        checkoutData={checkoutData}
        setCheckoutData={setCheckoutData}
        config={config}
      />
      <PaymentAccordion
        checkoutData={checkoutData}
        setCheckoutData={setCheckoutData}
      />
      <AddressDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
    </Accordion>
  );
}

export { AddressAccordion };
