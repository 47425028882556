import React, { useEffect } from 'react';

interface DrawerProps {
  direction?: 'left' | 'right' | 'top' | 'bottom';
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const Drawer: React.FC<DrawerProps> = ({
  direction = 'bottom',
  children,
  open,
  onClose,
}) => {
  const handleOutsideClick = (event: MouseEvent) => {
    const drawer = document.querySelector('.drawer');
    if (drawer && !drawer.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [open]);

  return (
    <>
      {open && <div className="drawer-backdrop" onClick={onClose}></div>}
      <div className={`drawer drawer-${direction} ${open ? 'open' : ''}`}>
        {children}
      </div>
    </>
  );
};

export default Drawer;
