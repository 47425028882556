import { AddressAccordionProps } from "../addressAccordion/AddressAccordion";
import { LoginInput } from "../loginInput/LoginInput";

function ContactAccordion({
  checkoutData,
  setCheckoutData,
  config,
}: AddressAccordionProps) {
  return (
    <div className="contact-accordion-container">
      <LoginInput
        checkoutData={checkoutData}
        setCheckoutData={setCheckoutData}
        config={config}
      />
    </div>
  );
}

export { ContactAccordion };
