import React, { useEffect } from 'react'
import { SearchProductList } from '../../components'
import { useAppDispatch, useAppSelector } from '../../appState/reduxTypedHooks'
import { headers } from "../../constant/titles";
import { setTitle } from '../../appState/componentSlices/utilSlice/utilSlice';
export const PopularProductList = () => {
    const dispatch = useAppDispatch();
    const PopularProducts = useAppSelector((state) => state.ProductsData.popularProducts)
    const loader = useAppSelector((state) => state.ProductsData.loader)
    useEffect(()=>{
        dispatch(setTitle(headers.PopularProduct));
    },[])
    return (

            <SearchProductList
                products={PopularProducts}
                loader={loader}
            />

    )
}
