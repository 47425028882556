import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/api/apiPaths";
interface ProductsDataInterface {
  allProducts: AllProducts[];
  loader: boolean;
  allProductsByCategory: AllProducts[];
  brands: AllProducts[];
  searchedProducts: searchedProducts;
  searchedCategorysBrands: AllProducts[]
  popularProducts: []
  categories: AllProducts[]
  plpLoader: boolean
  productInfo: Partial<Product>,
  productId: string
  similarProducts: Product[] | undefined
}
type searchedProducts = {
  status: number,
  message: string,
  data: AllProducts[],

}
type AllProducts = {
  name: any;
  _id: string;
  products: Product[];
};
export type Product = {
  _id: string
  slabPricing: any[];
  itemName: string;
  itemMRPperUnit: string;
  itemSellingPricePerUnit: string;
  images?: Images[];
  image?: Images[];
  itemCategory?: string
};

type Images = {
  public_id: string;
  secure_url: string;
  secureUrl: string;
};

const initialState: ProductsDataInterface = {
  allProducts: [],
  loader: false,
  allProductsByCategory: [],
  brands: [],
  searchedProducts: {
    status: 0,
    message: '',
    data: [],  
  },
  popularProducts: [],
  categories: [],
  searchedCategorysBrands: [],
  plpLoader : false,
  productInfo: {},
  productId: "",
  similarProducts: []
};

const THUNK_METHODS = {
  GET_PRODUCTS: "getProducts",
  GET_PRODUCTS_BY_CATEGORY: "getProductsByCategory",
  GET_BRANDS: "getBrands",
  SEARCH_PRODUCTS: "searchProducts",
  GET_POPULAR_PRODUCTS: "getPopularProducts",
  GET_CATEGORIES: "getAllCategories",
  CLEAR_SEARCH_PRODUCTS: "clearSearchedProducts",
  SEARCH_PRODUCTS_BY_CATEGORYS_BRANDS: "SearchProductsByCategorysOrBrands",
  SEARCH_CATEGORY: "searchCategorys",
  CLEAR_SEARCH_CATEGORY: "clearSearchedCategorys",
  GET_PRODUCT_BY_ID: "getProductById",
  SET_PRODUCT_ID_PDP: 'setProductIdPDP',
  GET_SIMILAR_PRODUCTS: 'getSimilarProducts'
};

export const getAllProducts = createAsyncThunk(
  THUNK_METHODS.GET_PRODUCTS,
  async (productBody: {}) => {
    const getProductsFeed = await fetch(`${process.env.REACT_APP_API_URL}/${API.PRODUCTS.GET_ALL_PRODUCTS}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productBody),
    });

    const allProducts = await getProductsFeed.json();
    return allProducts || [];
  }
);
export const getAllProductsByCategory = createAsyncThunk(
  THUNK_METHODS.GET_PRODUCTS_BY_CATEGORY,
  async (productBody: {}) => {
    const getProductsFeed = await fetch(`${process.env.REACT_APP_API_URL}/${API.PRODUCTS.GET_ALL_PRODUCTS_BY_BRAND_OR_CATEGORY}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productBody),
    });

    const allProductsByCategory = await getProductsFeed.json();
    return allProductsByCategory || [];
  }
);
export const getAllBrands = createAsyncThunk(
  THUNK_METHODS.GET_BRANDS,
  async (productBody: any) => {

    const getProductsFeed = await fetch(`${process.env.REACT_APP_API_URL}/api/allbrands`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productBody),
    });

    const allProductsByCategory = await getProductsFeed.json();
    return allProductsByCategory || [];
  }
);
export const getAllCategories = createAsyncThunk(
  THUNK_METHODS.GET_CATEGORIES,
  async (productBody: {}) => {
    const getProductsFeed = await fetch(`${process.env.REACT_APP_API_URL}/api/allCategories`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productBody),
    });

    const allProductsByCategory = await getProductsFeed.json();
    return allProductsByCategory || [];
  }
);


export const searchProductsByName = createAsyncThunk(
  THUNK_METHODS.SEARCH_PRODUCTS,
  async ({ itemName, limit, order }: { itemName: string; limit: number; order: string }) => {
    const searchResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/${API.PRODUCTS.SEARCH_PRODUCTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ itemName, limit, order }),
      }
    );
    const searchResults = await searchResponse.json();
    return searchResults || [];
  }
);

export const getPopularProducts = createAsyncThunk(
  THUNK_METHODS.GET_POPULAR_PRODUCTS,
  async ({ limit }: { limit: number }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${API.PRODUCTS.GET_POPULAR_PRODUCTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ limit }),
      }
    );
    const popularProducts = await response.json();
    return popularProducts || [];
  }
);

export const getSimilarProducts = createAsyncThunk(
  THUNK_METHODS.GET_SIMILAR_PRODUCTS,
  async ({ limit, itemCategory }: { limit: number, itemCategory: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${API.PRODUCTS.GET_SIMILAR_PRODUCTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ limit, itemCategory }),
      }
    );
    
    
    const similarProducts = await response.json();
    return similarProducts || [];
  }
);

export const clearSearchedProducts = createAsyncThunk(
  THUNK_METHODS.CLEAR_SEARCH_PRODUCTS,
  async () => {
    return [];
  }
);

export const SearchProductsByCategorysOrBrands = createAsyncThunk(
  THUNK_METHODS.SEARCH_PRODUCTS_BY_CATEGORYS_BRANDS,
  async ({ itemName, limit, order, categorysOrBrandsName, categorysOrBrands }: { categorysOrBrandsName: string, categorysOrBrands: string, itemName: string; limit: number; order: string }) => {
    const searchResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/${API.PRODUCTS.SEARCH_PRODUCTS_BY_BRAND_OR_CATEGORY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({categorysOrBrandsName, categorysOrBrands, itemName, limit, order }),
      }
    );
    const searchResults = await searchResponse.json();
    return searchResults || [];
  }
);


export const searchCategorys = createAsyncThunk(
  THUNK_METHODS.SEARCH_CATEGORY,
  async (products: AllProducts[]) => {
    return products;
  }
);
export const clearSearchedCategorys = createAsyncThunk(
  THUNK_METHODS.CLEAR_SEARCH_CATEGORY,
  async () => {
    return [];
  }
);

export const getProductById = createAsyncThunk(
  THUNK_METHODS.GET_PRODUCT_BY_ID,
  async (id: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${API.PRODUCTS.GET_PRODUCT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({id}),
      });
      const data = await response.json();
      return data || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export const setProductIdPDP = createAsyncThunk(
  THUNK_METHODS.SET_PRODUCT_ID_PDP,
  async (productId: string = "") => {
    return productId;
  }
);

export const AllProductsSlice = createSlice({
  name: "ProductsFeed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state, action) => {
        state.allProducts = action.payload || [];
        state.loader = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.allProducts = action.payload.data;
        state.loader = false;
      });
    builder
      .addCase(getAllProductsByCategory.pending, (state, action) => {
        state.allProductsByCategory = action.payload || [];
        state.loader = true;
        state.plpLoader = true;
      })
      .addCase(getAllProductsByCategory.fulfilled, (state, action) => {
        state.allProductsByCategory = action.payload.data;
        state.loader = false;
        state.plpLoader = false;
      });
    builder
      .addCase(getAllBrands.pending, (state, action) => {
        state.brands = action.payload || [];
        state.loader = true;
        state.plpLoader = false;
      })
      .addCase(getAllBrands.fulfilled, (state, action) => {
        state.brands = action.payload.data;
        state.loader = false;
      });
    builder
      .addCase(searchProductsByName.pending, (state) => {
        state.loader = true;
      })
      .addCase(searchProductsByName.fulfilled, (state, action) => {
        state.searchedProducts = action.payload || {
          status: 0,
          message: '',
          data: [],  
        };
        state.loader = false;
      })
      .addCase(searchProductsByName.rejected, (state) => {
        state.searchedProducts ={
          status: 0,
          message: '',
          data: [],  
        } ;
        state.loader = false;
      });
    builder
      .addCase(getPopularProducts.pending, (state) => {
        state.loader = true;
        state.plpLoader = true;
      })
      .addCase(getPopularProducts.fulfilled, (state, action) => {
        state.popularProducts = action.payload.data || [];
        state.loader = false;
        state.plpLoader = false;
      })
      .addCase(getPopularProducts.rejected, (state) => {
        state.popularProducts = [];
        state.loader = false;
        state.plpLoader = false;
      });
    builder
      .addCase(getAllCategories.pending, (state, action) => {
        state.categories = action.payload || [];
        state.loader = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {        
        state.categories = action.payload.data;
        state.loader = false;
      })
      .addCase(clearSearchedProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(clearSearchedProducts.fulfilled, (state) => {
        state.searchedProducts = {
          status: 0,
          message: '',
          data: [],  
        };
        state.loader = false;
      })
    builder
      .addCase(SearchProductsByCategorysOrBrands.pending, (state) => {
        state.loader = true;
      })
      .addCase(SearchProductsByCategorysOrBrands.fulfilled, (state, action) => {
        state.searchedProducts = action.payload ;
        state.loader = false;
      })
      .addCase(SearchProductsByCategorysOrBrands.rejected, (state) => {
        state.searchedProducts = {
          status: 0,
          message: '',
          data: [],  
        };
        state.loader = false;
      });
    builder
      .addCase(searchCategorys.pending, (state) => {
        state.loader = true;
      })
      .addCase(searchCategorys.fulfilled, (state, action) => {
        state.searchedCategorysBrands = action.payload;
        state.loader = false;
      });
    builder
      .addCase(clearSearchedCategorys.pending, (state) => {
        state.loader = true;
      })
      .addCase(clearSearchedCategorys.fulfilled, (state) => {
        state.searchedCategorysBrands = [];
        state.loader = false;
      });
    builder
      .addCase(getProductById.pending, (state) => {
        state.loader = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.productInfo = action.payload.data;
        state.loader = false;
      });
    builder
      .addCase(setProductIdPDP.fulfilled, (state, action) => {
        state.productId = action.payload;
      });
    builder
      .addCase(getSimilarProducts.pending, (state, action) => {  
        state.similarProducts = action.payload;
        state.loader = true;
      })
      .addCase(getSimilarProducts.fulfilled, (state, action) => {
        state.similarProducts = action.payload.data;
        state.loader = false;
      })
      .addCase(getSimilarProducts.rejected, (state, action) => {
        state.similarProducts = [];
        state.loader = false;
      });
  },
});


export default AllProductsSlice.reducer;
