import { useSelector } from "react-redux";
import { SearchProductList } from "../../components"
import { RootState } from "../../appState/store";
import { useEffect } from "react";
import { useAppDispatch } from "../../appState/reduxTypedHooks";
import { setTitle } from "../../appState/componentSlices/utilSlice/utilSlice";
import { headers } from "../../constant/titles";


export const SearchProduct = () => {
  const dispatch = useAppDispatch();
  const searchedProducts = useSelector((state: RootState) => state.ProductsData.searchedProducts);
  const loader = useSelector((state: RootState) => state.ProductsData.loader);
  useEffect(()=>{
    dispatch(setTitle(headers.search));
},[])
  return (
    <div className="search-products-container">
      <div className="main-content">
        <SearchProductList 
        products={searchedProducts}
        loader={loader}
        />
      </div>
    </div>
  )
}
