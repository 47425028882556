import React from "react";
import { Accordion } from "../accordion/Accordion";
import { Card } from "../card/Card";
import "./OrderSummary.scss";

interface OrderSummaryAccordionProps {
  cart: any;
}

function OrderSummary(props: OrderSummaryAccordionProps) {
  const { cart } = props;
  const { cartTotalSP, deliveryCharge, discountAmount } = cart?.productInCart;
  return (
    <div className="order-summary-container">
      <Accordion
        heading="Order Summary"
        footer={
          <div className="charge">
            {
              <div className="grand-total order-summary">
               <p> Grand Total:</p>
                <p>{cartTotalSP + deliveryCharge}{" "}</p>
              </div>
            }
          </div>
        }
        showDetails={true}
      >
        <div className="charge">
          <div className="oreder-summary-inside">
          <p>Product Total: </p> <p>{cartTotalSP}</p>
          </div>
          <div className="oreder-summary-inside">
          <p>Delivery Charges: </p> <p>{deliveryCharge}</p>
          </div>
          <div className="oreder-summary-inside">
          <p className="grand-total">
            Grand Total:
          </p>
          <p> {cartTotalSP + deliveryCharge}{" "}</p>
          </div>
        </div>
      </Accordion>
      {discountAmount ? (
        <Card borderRadius={8} backgroundColor={"#008aff"}>
          <p>😃</p>
          <p>
            Hooray!! You got Rs {discountAmount} total discount on your order
          </p>
        </Card>
      ) : (
        ""
      )}
    </div>
  );
}

export { OrderSummary };
