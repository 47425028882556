import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearchState {
    isSearch: boolean;
}

const initialState: SearchState = {
    isSearch: false,
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setIsSearch(state, action: PayloadAction<boolean>) {
            state.isSearch = action.payload;
        },
    },
});

export const { setIsSearch } = searchSlice.actions;
