import { useEffect, useState } from "react";
import { AddressAccordionProps } from "../addressAccordion/AddressAccordion";
import { useAppDispatch, useAppSelector } from "../../appState/reduxTypedHooks";
import { UserDetailsInterface } from "../../appState/componentSlices/userSlice/@types/User.Initerface";
export interface UserInterface {
  phone?: string;
  otp?: number;
  shippingAddress?: [
    {
      address: string;
      city: string;
      landmark: string;
      currentLocation: string;
    }
  ];
}
// export type LoginProps = {
//     checkoutData: any;
//     setCheckoutData: any;
//     config: any;
//   };
function LoginInput({ checkoutData, setCheckoutData }: AddressAccordionProps) {
  const loggedInUser: UserDetailsInterface = useAppSelector(
    (state) => state.userOtp.userDetails
  );
  const [showPara, setShowPara] = useState(true);
  const dispatch = useAppDispatch();
  const handleInputChange = (e: any) => {
    e.preventDefault();
    if (e.target.value.length === 10) {
      setShowPara(false);
    } else {
      setShowPara(true);
    }
  };
  useEffect(() => {
    if (loggedInUser?.phone) {
      dispatch(
        setCheckoutData({ contactNumber: loggedInUser.phone.toString().slice(-10) })
      );
      setShowPara(false);
    }
  }, [loggedInUser?.phone]);
  return (
    <div>
      <label htmlFor="contact">
        Contact <sup className="required-field">*</sup>{" "}
      </label>
      <div className="contact-input-container">
        <div className="input-container">
          <img
            className="flag-image"
            src="Images/indianFlag.svg"
            alt="flag-icon"
          />
          <p className="country-code">+91</p>
          <input
            name="contact"
            id="contact"
            required
            onChange={(e) => {
              Number(e.target.value ?? '0') >= 0 &&  dispatch(
                setCheckoutData({ contactNumber: e.target.value })
              );
              handleInputChange(e);
            }}
            value={checkoutData.contactNumber}
            className="contact-number-input"
            type="number"
            placeholder="Enter number"
          />
        </div>
        {showPara && <p className="error-message">Enter valid number</p>}
      </div>
    </div>
  );
}

export { LoginInput };
