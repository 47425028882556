export const generateWhatsAppMessage = (customerName: string, orderNumber: string): string => {
   const contactSupportNumber = process.env.REACT_APP_CONTACT_SUPPORT_NUMBER;
    const message = `
      Hi,
  
      I need assistance with my recent order. Here are the details:
  
      Customer Name: ${customerName}
      Order: ${orderNumber}
  
      Could you please help me resolve this as soon as possible? I’d appreciate your support!
  
      Thank you,
    `;
  
    // Ensure the message is properly encoded
    const encodedMessage = encodeURIComponent(message.trim());
  
    // Construct the URL for WhatsApp
    const url = `https://wa.me/${contactSupportNumber}?text=${encodedMessage}`;  
    return url;
  };
  