const getCookie = (cookieKey: string): string | undefined => {
  // First, try to get the value from cookies
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${cookieKey}=`))
    ?.slice(cookieKey.length + 1);

  // If cookie is not found, try getting the value from localStorage
  if (!cookieValue) {
    return localStorage.getItem(cookieKey) || undefined;
  }

  return cookieValue;
};

export default getCookie;
