import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useCart from "./hooks/useCart";
import {
  CartCheckout,
  ErrorBoundary,
  Footer,
  Login,
  Modal,
  NavBar,
  Toast,
} from "./components";
import { useAppDispatch, useAppSelector } from "./appState/reduxTypedHooks";
import {
  getAllBrands,
  getAllCategories,
  getAllProducts,
  getPopularProducts,
} from "./appState/componentSlices/allProductsFeed";
import ReactPortal from "./utils/ReactPortals";
import "./styles/index.scss";
import {
  getUserDetails,
  setModal,
  setOtpScreen,
} from "./appState/componentSlices/userSlice/userLoginSlice";
import { batch } from "react-redux";
import { RootState } from "./appState/store";
import { subscribeUserToPush } from "./utils/push";
import { requestNotificationPermission } from "./utils/notifications";
const App = () => {
  const { cartData } = useCart();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const profileRef = useRef<HTMLDivElement>(null);
  const loginRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useAppSelector(
    ({ userOtp: { isLoggedIn } }) => isLoggedIn
  );
  const fetchCartData = async () => {
    await cartData();
  };

  const cartLength = useAppSelector(
    (state: RootState) => state.productInCart?.productInCart?.length ?? 0
  );
  const showModal = useAppSelector(
    (state: RootState) => state.userOtp?.showModal ?? false
  );

  const [showProfileBar, setShowProfileBar] = useState(false);
  const onOutsideClick = (e: any) => {
    profileRef.current &&
      !profileRef.current.contains(e.target as Node) &&
      setShowProfileBar(false);
    loginRef.current &&
      !loginRef.current.contains(e.target as Node) &&
      (() => {
        dispatch(setModal(false));
        dispatch(setOtpScreen(false));
      })();
  };
  useEffect(() => {
    batch(() => {
      dispatch(
        getAllBrands({
          productBody: { groupBy: "itemCategory", matchByField: {} },
        })
      );
      dispatch(
        getAllCategories({
          productBody: { groupBy: "itemBrandName", matchByField: {} },
        })
      );
      dispatch(getUserDetails());
      dispatch(getPopularProducts({ limit: 10 }));
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchCartData();
    }
  }, [cartLength]);

  const handleEnableNotifications = async () => {
    const permission = await requestNotificationPermission();
    if (permission === "granted") {
      const subscription = await subscribeUserToPush();
      if (subscription) {
        // Send subscription to the server
        await fetch(`${process.env.REACT_APP_API_URL}/api/subscribe`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(subscription),
        });
      }
    } else {
      console.log("Notification permission denied");
    }
  };

  useEffect(() => {
    // disable the notification permission for now
    //  handleEnableNotifications();
  }, []);

  const isCheckoutPage = location.pathname === "/checkout";
  const isCartPage = location.pathname === "/cart";
  const isPDP = location.pathname === "/product-description";

  const { cart } = useCart();
  const { productInCart: { cartTotalSP = "" } = {} } = cart;

  return (
    <div className="container" onMouseDown={onOutsideClick}>
      <Toast />
      <NavBar
        profileRef={profileRef}
        showProfileBar={showProfileBar}
        setShowProfileBar={setShowProfileBar}
      />
      <ErrorBoundary>
        <div
          className={`home-container 
            ${!(isCartPage && isCheckoutPage) ? "cart-home-container" : ""}
            ${isPDP ? "full-page-home-container" : ""}
          `}
        >
          <div className="main-content">
            <Outlet />
          </div>
        </div>
      </ErrorBoundary>
      <ReactPortal>
        <Modal showModal={showModal}>
          <Login loginRef={loginRef} />
        </Modal>
      </ReactPortal>
      {isCartPage ? (
        cart?.productInCart && <CartCheckout cartTotalSP={cartTotalSP} />
      ) : (
        <Footer />
      )}
    </div>
  );
};

export default App;
